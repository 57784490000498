@import '~utils/styles/styles.scss';

.course-session {
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  &-summary {
    display: flex;
    align-items: center;
    @media screen and (max-width: $mobile-size) {
      display: block;
    }

    &__header {
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 1.33;
      letter-spacing: 0.12px;
      color: #000000;
      margin: 0;

      @media screen and (max-width: $mobile-size) {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__number-badge {
      background-color: $dark-ocean-blue-color;
      color: white;
      padding: 4px 12px;
      font-size: 0.6875rem;
      font-weight: 900;
      line-height: 1.45;
      letter-spacing: normal;
      text-align: center;
      border-radius: 10px;
      vertical-align: middle;
    }
    &__number-badge_num {
      @media screen and (max-width: $mobile-size) {
        margin: 0px 10px 0px 0px;
      }
    }

    &__title,
    &__status {
      margin: 0 10px 0 10px;
      @media screen and (max-width: $mobile-size) {
        font-size: 12px;
      }
    }

    &__type,
    &__status {
      font-style: italic;
      font-size: 13.7px;
      color: darkgray;
      @media screen and (max-width: $mobile-size) {
        font-size: 12px;
      }
    }
    &__type_session {
      @media screen and (max-width: $mobile-size) {
        position: relative;
        bottom: 107px;
        left: 223px;
      }
    }

    &__status {
      @media screen and (max-width: $mobile-size) {
        font-size: 12px;
        width: 50%;
      }
    }
    &__type {
      @media screen and (max-width: $mobile-size) {
        // position: relative;
        // bottom: 54px;
      }
    }
    &__reschedule {
      position: absolute;
      right: 60px;
      border-radius: 2px;
      background-color: $light-brown;
      color: $white;
      cursor: pointer;
      padding: 4px;
      font-weight: 700;
      font-size: 14px;
      border: 1px solid;
      outline: none;
      &:hover {
        background-color: darken($light-brown, 5%);
      }
      &:disabled {
        background-color: #ededed;
        color: #9b9b9b;
        fill: #9b9b9b; /* for svg */
        cursor: not-allowed;
        border: none;
      }
      @media screen and (max-width: $mobile-size) {
        position: absolute;
        right: 15px;
        top: 60px;
      }
    }
    &__delete {
      position: absolute;
      right: 150px;
      border-radius: 2px;
      background-color: $light-brown;
      color: $white;
      cursor: pointer;
      padding: 4px;
      font-weight: 700;
      font-size: 14px;
      border: 1px solid;
      outline: none;
      &:hover {
        background-color: darken($light-brown, 5%);
      }
      &:disabled {
        background-color: #ededed;
        color: #9b9b9b;
        fill: #9b9b9b; /* for svg */
        cursor: not-allowed;
        border: none;
      }
      @media screen and (max-width: $mobile-size) {
        position: absolute;
        right: 105px;
        top: 60px;
        font-size: 13px;
      }
    }
    &__reschedule2 {
      // position: absolute;
      // right: 60px;
      padding: 7px 25px;
      border-radius: 5px;
      font-weight: 600;
      margin-right: 20px;
      background-color: $light-brown;
      color: $white;
      cursor: pointer;
      font-size: 14px;
      border: 1px solid;
      outline: none;
      &:hover {
        background-color: darken($light-brown, 5%);
      }
      &:disabled {
        background-color: #ededed;
        color: #9b9b9b;
        fill: #9b9b9b; /* for svg */
        cursor: not-allowed;
        border: none;
      }
      @media screen and (max-width: $mobile-size) {
        position: static;
      }
    }
  }

  &-details {
    padding-left: 34px;
    width: 100%;

    @media screen and (max-width: $tablet) {
      padding-left: 0px;
    }

    &__header {
      margin: 0 0 15px 0;

      @media screen and (max-width: $mobile-size) {
        font-size: 16px;
      }
    }

    &-session-times {
      &__item {
        &:not(:last-child) {
          margin-bottom: 25px;
        }
      }
    }
  }
  &-summary-container {
    display: flex;
    align-items: center;
    @media screen and (max-width: $mobile-size) {
      align-items: flex-start;
      flex-direction: column;
      height: 120px;
      justify-content: space-around;
    }
  }
}
.course-session_main {
  display: flex;
  align-items: center;
}

.Expansion_Panel {
  @media screen and (max-width: $mobile-size) {
    display: block;
  }
}

.mbView_container_live_session {
  @media screen and (max-width: $mobile-size) {
    display: flex;
    padding: 8px 0px 0px 0px;
  }
}
