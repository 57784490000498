.container {
    width: 612px;
    display: flex;
    justify-content: space-between;
    background-color: #ffff;
    border: 1px solid #DFE3E4;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px #00000033;
    padding: 12px;

    @media screen and (max-width: 768px) {
        width: 100%;
        padding: 4.5px 10px
    }

    .input {
        font-family: 'Poppins';
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        text-align: left;
        color: #959595;
        margin-left: 8px;

        @media screen and (max-width : 768px) {
            margin-left: 4px;
            font-size: 14px;
        }
    }
}

.outlinedContainer {
    width: 100%;
    padding: 5px;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid rgba(223, 227, 228, 1);

    .input {
        font-size: 14px !important;
        font-family: 'Poppins' !important;
        font-weight: 400;
        line-height: 21px;
        color: rgba(149, 149, 149, 1);

        @media screen and (max-width : 991px) {
            margin-left: -1px;
        }
    }
}

.filledContainer {
    width: 20vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.15);

    @media screen and (max-width : 768px) {
        width: 100%;
        padding: 2px 8px;
    }

    .filledInput {
        font-family: 'Poppins';
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        color: rgba(223, 227, 228, 1);
        margin-left: 0px;

        @media screen and (max-width : 768px) {
            margin-left: -1px;
            font-size: 12px;
            line-height: 18px;
        }
    }
}