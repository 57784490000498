@import 'styles/varibles.scss';
@import '~utils/styles/styles.scss';

.communtiy-attachments-container {
  .image-wrap-original {
    // width: 655px;
    height: 369px;
    border-radius: 8px;
    display: flex;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 5px;
    border: 1px solid #0000000d;

    @media screen and (max-width: $lg-desktop) {
      & {
        width: 100%;
      }
    }
  }

  .image-wrap {
    width: 655px;
    height: 655px;
    border-radius: 8px;
    display: flex;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 5px;
    border: 1px solid #0000000d;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $lg-desktop) {
      & {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .image-wrap-landscape {
    width: 655px;
    height: 369px;
    border-radius: 8px;
    display: flex;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 5px;
    border: 1px solid #0000000d;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $lg-desktop) {
      & {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .image-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;

    @media screen and (max-width: $lg-desktop) {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }

  }

  .image-wrap-two-attachments {
    width: 322px;
    height: 655px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin-right: 5px;

    @media screen and (max-width: $lg-desktop) {
      & {
        position: absolute;
        top: 0;
        left: 0;
        width: 49%;
        height: 100%;

        &:last-of-type {
          left: 50%;
        }
      }
    }
  }

  .image-wrap-row {
    width: 655px;
    height: 322.5px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 5px;
    border: 1px solid #0000000d;

    @media screen and (max-width: $lg-desktop) {
      & {
        position: absolute;
        top: 0;
        width: 100%;
        height: 49%;
      }
    }
  }

  .modified-image-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;

    @media screen and (max-width: $lg-desktop) {
      & {
        position: absolute;
        top: 50%;
        width: 100%;
        height: 50%;
      }
    }
  }

  .modified-image-wrap-three-attachments {
    width: 322.5px;
    height: 322.5px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin-right: 5px;

    @media screen and (max-width: $lg-desktop) {
      & {
        width: 50%;
        height: 100%;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  .modified-image-wrap-multiple-attachments {
    width: 214.67px;
    height: 322.5px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin-right: 5px;

    @media screen and (max-width: $lg-desktop) {
      & {
        width: 33.3%;
        height: 100%;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .image-overlay {
    //overlay image
    width: 214.67px;
    height: 322.5px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin-right: 5px;
    position: relative;
    z-index: 1;

    @media screen and (max-width: $lg-desktop) {
      & {
        width: 33.3%;
        height: 100%;
      }
    }
  }

  .compress-image-overlay {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .image-text {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: 0.12px;
    color: #FFFFFF;
    flex: none;
    order: 3;
    flex-grow: 0;
    z-index: 3;

    @media screen and (max-width: $mobile-size) {
      & {
        font-weight: 900;
        font-size: 15.3893px;
        line-height: 15px;
        letter-spacing: 0.0577099px;
      }
    }
  }
}

.unifiedcommuntiy-attachments-container {
  .image-wrap-original {
    width: 100%;
    height: 369px;
    border-radius: 8px;
    display: flex;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 5px;
    border: 1px solid #0000000d;

    @media screen and (max-width: $tablet) {
      & {
        width: 100%;
      }
    }
  }

  .image-wrap {
    width: 655px;
    height: 655px;
    border-radius: 8px;
    display: flex;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 5px;
    border: 1px solid #0000000d;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $tablet) {
      & {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .image-wrap-landscape {
    width: 655px;
    height: 369px;
    border-radius: 8px;
    display: flex;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 5px;
    border: 1px solid #0000000d;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $tablet) {
      & {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .image-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;

    @media screen and (max-width: $tablet) {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }

  }

  .image-wrap-two-attachments {
    width: 322px;
    height: 655px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin-right: 5px;

    @media screen and (max-width: $tablet) {
      & {
        position: absolute;
        top: 0;
        left: 0;
        width: 49%;
        height: 100%;

        &:last-of-type {
          left: 50%;
        }
      }
    }
  }

  .image-wrap-row {
    width: 655px;
    height: 322.5px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 5px;
    border: 1px solid #0000000d;

    @media screen and (max-width: $tablet) {
      & {
        position: absolute;
        top: 0;
        width: 100%;
        height: 49%;
      }
    }
  }

  .modified-image-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;

    @media screen and (max-width: $tablet) {
      & {
        position: absolute;
        top: 50%;
        width: 100%;
        height: 50%;
      }
    }
  }

  .modified-image-wrap-three-attachments {
    width: 322.5px;
    height: 322.5px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin-right: 5px;

    @media screen and (max-width: $tablet) {
      & {
        width: 50%;
        height: 100%;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  .modified-image-wrap-multiple-attachments {
    width: 214.67px;
    height: 322.5px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin-right: 5px;

    @media screen and (max-width: $tablet) {
      & {
        width: 33.3%;
        height: 100%;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .image-overlay {
    //overlay image
    width: 214.67px;
    height: 322.5px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin-right: 5px;
    position: relative;
    z-index: 1;

    @media screen and (max-width: $tablet) {
      & {
        width: 33.3%;
        height: 100%;
      }
    }
  }

  .compress-image-overlay {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .image-text {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: 0.12px;
    color: #FFFFFF;
    flex: none;
    order: 3;
    flex-grow: 0;
    z-index: 3;

    @media screen and (max-width: $mobile-size) {
      & {
        font-weight: 900;
        font-size: 15.3893px;
        line-height: 15px;
        letter-spacing: 0.0577099px;
      }
    }
  }
}