.card {
    // width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    gap: 8px;
    padding: 8px;
    position: relative;

    @media screen and (max-width: 768px) {
        width: 280px;
    }

    &:hover {
        box-shadow: 0 1px 3px rgba(23, 23, 23, 0.24);
        cursor: pointer;
    }

    .profile {
        .image {
            width: 74px;
            height: 74px;
            border-radius: 37px;
        }

        .icon {
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
        }
    }

    .coachName {
        font-family: 'Poppins';
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        color: rgba(43, 43, 43, 1);

    }

    .clients_services {
        display: flex;
        justify-content: center;
        align-items: center;

        .clientsCount {
            flex-basis: 50%;
            font-family: 'Poppins';
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            color: rgba(43, 43, 43, 1);
            gap: 4px;
            display: flex;

            .clients {
                font-family: 'Poppins';
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                color: rgba(74, 74, 74, 1);
            }

        }

        .servicesCount {
            flex-basis: 50%;
            font-family: 'Poppins';
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            color: rgba(43, 43, 43, 1);
            gap: 4px;
            display: flex;

            .services {
                font-family: 'Poppins';
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                color: rgba(74, 74, 74, 1);
            }

        }
    }

    .tags {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        @media screen and (max-width: 768px) {
            width: 280px;
        }

        .tag {
            background: rgba(245, 245, 245, 1);
            font-family: 'Poppins';
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            text-align: left;
            padding: 4px 10px 4px 10px;
            border-radius: 4px;
        }
    }

}