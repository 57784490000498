.boxContainer {
    display: flex;
    height: 80px;
    gap: 20px;

    .inactive {
        border-bottom: 3px solid #000000;
        display: flex;
        flex-direction: row;
        align-items: center;

        .inactiveBtn {
            background-color: transparent;
            display: flex;
            justify-content: center;
            font-size: 16px;
            font-weight: 400;
            color: #FFFFFF;
            min-width: auto;
            font-family: 'Poppins';
            align-items: center;
            gap: 5px;
            padding: 1rem 0px;

            .plusBtn {
                background-color: #E1BB62;
                max-width: 32px;
                min-width: 32px;
                max-height: 26px;
                min-height: 26px;
                border-radius: 4px;
                font-size: 12px;
                font-weight: 500;
                font-family: "Poppins";
                padding: 10px 18px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .active {
        border-bottom: 3px solid #E1BB62;
        display: flex;
        flex-direction: row;
        align-items: center;

        .activeBtn {
            background-color: transparent;
            display: flex;
            justify-content: center;
            font-size: 16px;
            font-weight: 600;
            color: #FFFFFF;
            min-width: auto;
            font-family: 'Poppins';
            align-items: center;
            gap: 5px;
            padding: 1rem 0px;

            .plusBtn {
                background-color: #E1BB62;
                max-width: 32px;
                min-width: 32px;
                max-height: 26px;
                min-height: 26px;
                border-radius: 4px;
                font-size: 12px;
                font-weight: 500;
                font-family: "Poppins";
                padding: 10px 18px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

.responsiveBoxContainer {
    .responsiveBtnWrapper {
        color: #000000;
        display: flex;
        flex-direction: column;

        .responsiveBtn {
            background-color: transparent;
            display: flex;
            font-size: 16px;
            font-weight: 400;
            color: #000000;
            min-width: auto;
            font-family: 'Poppins';
            align-items: center;
            gap: 5px;
            padding: 1rem 0px;

            .plusBtn {
                background-color: #E1BB62;
                min-width: 25px;
                min-height: 18px;
                border-radius: 4px;
                font-size: 14px;
                font-weight: 500;
                font-family: 'Poppins';
                padding: 8px
            }

            .text {
                font-family: 'Poppins';
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
            }
        }

        .responsiveActiveBtn {
            background-color: transparent;
            display: flex;
            font-size: 16px;
            font-weight: 600;
            color: #000000;
            min-width: auto;
            font-family: 'Poppins';
            align-items: center;
            gap: 5px;
            padding: 1rem 0px;

            .plusBtn {
                background-color: #E1BB62;
                min-width: 25px;
                min-height: 18px;
                border-radius: 4px;
                font-size: 14px;
                font-weight: 500;
                font-family: 'Poppins';
                padding: 8px
            }

            .text {
                font-family: 'Poppins';
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
            }
        }
    }

    .responsiveBtnWrapper:nth-child(4) {
        border-bottom: 1px solid #DFE3E4;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        margin-bottom: 5px;
    }
}