.allPageContainer {
    min-height: calc(100vh - 271px);
    margin-top: 80px;
    background-image: url('../../../../assets/bg_iamge.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;

    @media screen and (max-width: 768px) {
        min-height: calc(100vh - 152px);
        margin-top: 80px;
        background-image: none;
    }

    .bannerSection {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 60px 80px 100px 80px;
        gap: 42px;


        @media screen and (max-width: 768px) {
            padding: 32px 16px 24px 16px;
            background: linear-gradient(2.64deg, rgba(255, 253, 247, 0) 2.47%, #FFFDF7 42.58%);
        }

        .contentSection {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;

            @media screen and (max-width: 768px) {
                width: 100%;
                gap: 15px;
            }

            .text {
                max-width: 846px;
                color: #2B2B2B;
                font-size: 52px;
                font-weight: 700;
                font-family: 'Poppins';
                text-align: center;
                line-height: 62px;
            }

            .responsiveText {
                color: #2B2B2B;
                font-size: 32px;
                font-weight: 700;
                font-family: 'Poppins';
                text-align: center;
                line-height: 38px;
            }

            .shareLink {
                color: #E1BB62;
                text-transform: capitalize;
                text-decoration: underline;
                font-size: 24px;
                font-weight: 600;
                font-family: 'Poppins';
                text-align: center;
                line-height: 32px;
                cursor: pointer;

                @media screen and (max-width : 768px) {
                    font-size: 20px;
                }
            }
        }

        .categoriesSection {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex-wrap: wrap;
            width: 100%;
            // padding: 0px 50px;

            @media screen and (max-width: 768px) {
                width: 100%;
                gap: 40px;
                padding: 0px !important;
                flex-wrap: wrap;
            }
        }
    }

    .allServiceCards {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 100%;
        padding: 0px 80px;
        margin-bottom: 50px;
        // margin-top: -25px;

        @media screen and (max-width: 768px) {
            padding: 0px;
        }

        .serviceCardsHeader {
            height: 44px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            @media screen and (max-width: 768px) {
                flex-direction: column;
                gap: 8px;
                padding: 0px 16px;
            }

            .filterResponsiveIcon {
                width: 38px;
                height: 38px;
                position: absolute;
                right: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgb(255, 255, 255);
                box-shadow: 0px 0px 4.75px 0px rgba(0, 0, 0, 0.1607843137);
                border: 1.19px solid #DFE3E4;
                border-radius: 33.25px;
            }
        }

        .filterContainer {
            position: relative;

            @media screen and (max-width: 768px) {
                align-self: flex-end;
            }

            .filterBtn {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 7px 0px 7px 0px;
                gap: 8px;
                cursor: pointer;

                .text {
                    font-family: 'Poppins';
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    color: #2b2b2b;
                }
            }
        }
    }
}