@import "~utils/styles/styles.scss";

.master-calendar-container {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  width: 100%;

  @media screen and (max-width: 575px) {
    padding: 10px;
  }

  .calendar-custom-header {
    display: flex;
    justify-content: space-between;

    .calendar-create-session-button {
      height: 38px;
      padding: 5px 20px;
      width: fit-content;
      min-width: unset;
      border-radius: 4px;
      display: block;
      margin-left: auto;
      display: flex;
      z-index: 99;
      white-space: nowrap;

      p {
        margin: auto;
        margin-top: 7px;
      }

      svg {
        margin: auto;
        margin-right: 6px;
        font-size: 20px;
      }

      @media screen and (max-width: 992px) {
        font-size: 12px;
        padding: 5px 10px;

        svg {
          font-size: 16px;
        }
      }
    }
    .calendar-add-availability-button {
      height: 30px;
      padding: 5px 20px;
      width: fit-content;
      min-width: unset;
      border-radius: 4px;
      display: block;
      margin-left: auto;
      display: flex;
      z-index: 99;
      white-space: nowrap;

      p {
        margin: auto;
        margin-top: 7px;
      }

      svg {
        margin: auto;
        margin-right: 6px;
        font-size: 20px;
      }

      @media screen and (max-width: 992px) {
        font-size: 12px;
        padding: 0px 5px;

        svg {
          font-size: 16px;
        }
      }
    }
  }

  .attached-calendars {
    display: block;
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    float: right;
    flex-wrap: wrap-reverse;
    justify-content: flex-end;

    .attached-calendar {
      height: 38px;
      padding: 5px 0.62rem;
      background-color: white;
      color: #116582;
      border-radius: 4px;
      border: #116582 1px solid;
      margin-left: 15px;
      font-weight: 500;
      display: flex;
      margin-top: 10px;

      .visibility-icon {
        font-size: 20px;
        margin: auto;
        margin-lecalendarsvgft: 6px;
      }

      p {
        font-size: 14px;
        margin: auto;
      }

      svg {
        color: #4a4a4a;
        cursor: pointer;
        margin: auto;
      }
      svg:not(.provider-logo) {
        margin-left: 0.5rem;
      }
      svg.provider-logo {
        cursor: default;
        margin-right: 0.5rem;
      }
    }

    .add-my-calendar-button {
      height: 38px;
      padding: 5px 20px;
      width: fit-content;
      min-width: unset;
      border-radius: 4px;
      background-color: white;
      color: #116582;
      border: #116582 1px solid;
      font-weight: 500;
      display: flex;
      white-space: nowrap;
      margin-top: 10px;
      margin-left: 15px;

      p {
        font-size: 14px;
        margin-top: 6px;
      }

      svg {
        margin: auto;
        margin-right: 6px;
        font-size: 20px;
      }
    }

    @media screen and (max-width: 992px) {
      .add-my-calendar-button {
        padding: 6px 10px;

        p {
          font-size: 12px;
          margin-top: 5px;
        }

        svg {
          font-size: 16px;
        }
      }
    }
  }

  .calendar-view-tab-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    white-space: nowrap;
    align-items: baseline;
    margin-right: 20px;

    .toolbar-view-tab-button {
      border-width: 0;
      border-radius: 4px;
      margin: auto;
      padding: 4px 16px;
      background-color: white;
      color: #71717a;
      font-family: Avenir;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      height: 36px;

      @media screen and (max-width: 992px) {
        padding: 4px 10px;
        font-size: 12px;
      }
    }

    .toolbar-view-tab-button.active {
      background-color: #d1b989;
      color: white;
    }
  }

  @media screen and (max-width: 575px) {
    .calendar-view-tab-container {
      display: none;
    }
  }
  @media screen and (max-width: $mobile-size-sm) {
    .calendar-custom-header {
      flex-direction: column;
      gap: 10px;

      .master-calender-toolbar-container {
        justify-content: right;
        padding-right: 0;
      }
      .master-calender-toolbar-container-leftaligned {
        justify-content: left;
        padding-right: 0;
      }
    }
  }
  @media screen and (max-width: 575px) {
    .custom-calender-toolbar {
      .calendar-custom-header {
        display: inherit;
      }
      .custom-calender-dropdown {
        display: flex;
        justify-content: inherit;
        width: 100%;
        margin-bottom: 10px;
        .master-calender-toolbar-container {
          justify-content: left;
          width: 70%;
          padding-top: 4px;
        }
        .calender-current-view-select {
          justify-content: right;
          padding-right: 0;
          width: 30%;
          height: 35px;
          background: #f1f8ff;
          border: none;
        }
      }
    }
  }

  .cohere-master-calendar {
    &.custom-day-calendar-view {
      .rbc-time-view {
        .rbc-time-content {
          .rbc-today {
            background-color: white;
          }
        }
      }
    }

    .rbc-toolbar {
      justify-content: center;

      .rbc-btn-group {
        button {
          border-width: 0;
          border-radius: 4px;

          &.rbc-active {
            background-color: #d1b989;
            color: white;
          }
        }

        &:last-of-type {
          display: flex;
          flex-direction: row-reverse;
        }

        &:first-of-type {
          position: absolute;
          left: 0;
          background-color: #e6e6e6;
          border-radius: 2px;
        }
      }
    }

    .rbc-month-view,
    .rbc-time-view {
      margin-top: 15px;
      width: unset;
      overflow-x: auto;
      scrollbar-color: red;
    }

    .rbc-month-view {
      border: none;

      .rbc-month-header {
        .rbc-header {
          span {
            font-family: Avenir;
            font-size: 12px;
            font-weight: 800;
            line-height: 28px;
            letter-spacing: 0em;
            float: left;
            color: #71717a;
          }
        }
      }
    }

    .rbc-time-view {
      max-height: 610px;
      border: none;
      overflow-x: auto;

      /* Width of the scrollbar */
      &::-webkit-scrollbar {
        width: 8px;
      }

      /* Track (the area where the scrollbar is located) */
      &::-webkit-scrollbar-track {
        background: #dfdfdf;
      }

      /* Handle (the draggable part of the scrollbar) */
      &::-webkit-scrollbar-thumb {
        background: #969696;
        border-radius: 10px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #235b72;
      }

      .rbc-time-header {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 99;

        .rbc-time-header-gutter {
        }

        .rbc-time-header-content {
          // border: none;
          .rbc-time-header-cell {
            .rbc-header {
              padding-top: 8px;
              padding-bottom: 8px;

              a {
                color: #71717a;
                font-family: Avenir;
                font-size: 12px;
                font-style: normal;
                font-weight: 800;
                line-height: 12px;
                float: left;
              }
            }
          }
        }

        .rbc-allday-cell {
          display: none;
        }
      }

      .rbc-time-content {
        border: none;

        .rbc-day-slot {
          .rbc-events-container {
            margin-right: 0;

            .rbc-event-content {
              span {
                margin: auto 0;
              }

              svg {
                min-width: 14px;
              }
            }
          }
        }

        .rbc-timeslot-group {
          min-height: 72px;
        }

        .rbc-time-gutter {
          color: #71717a;
          font-family: Avenir;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          min-width: 80px;

          /* 133.333% */
          .rbc-timeslot-group {
            border-bottom: none;
          }
        }
      }
    }

    .rbc-row-content {
      .rbc-row {
        .rbc-date-cell {
          a {
            font-size: 18px;
            font-family: Avenir;
            float: left;
            margin-left: 2px;
          }
        }

        .rbc-row-segment {
          .rbc-event {
            .rbc-event-content {
              div {
                display: flex;
                gap: 5px;
                align-items: center;

                svg {
                  min-width: 8px;
                }

                p {
                  max-width: 80%;
                  margin: 0;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
          }

          .rbc-show-more {
            background: unset;
          }
        }
      }
    }
  }
}

.current-date-status {
  padding: 8px 20px 10px 20px;
  align-items: center;
  background: #e7e7e7;
  font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  border-radius: 4px;
  margin: auto 0 auto 5px;

  @media screen and (max-width: 992px) {
    font-size: 12px;
    padding: 8px 15px 8px 15px;
  }

  @media screen and (max-width: 575px) {
    display: none;
  }
}

.master-calender-toolbar-container {
  display: flex;
  align-items: baseline;
  padding: 0px 10px 0px 0px;
  justify-content: right;

  .btn-back {
    padding: 10px 8px;
    margin: 1px;
    font-size: 12px;
    background: #f1f8ff;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    font-weight: 400;

    @media screen and (max-width: 992px) {
      padding: 8px 4px;
    }
  }

  .btn-current {
    padding: 8px 20px 12px 20px;
    margin: 1px;
    font-size: 14px;
    font-weight: 500;
    background: #f1f8ff;
    white-space: nowrap;

    @media screen and (max-width: 992px) {
      padding: 6px 15px 10px 15px;
    }
  }

  .btn-next {
    padding: 10px 8px;
    margin: 1px;
    font-size: 12px;
    background: #f1f8ff;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    font-weight: 400;

    @media screen and (max-width: 992px) {
      padding: 8px 4px;
    }
  }
}

.master-calendar-reschedule-modal {
  .cohere-modal {
    width: 775px;

    .master-date-picker {
      display: block;
      width: 100%;
      margin-top: 20px;

      .MuiInput-root {
        width: 100%;
      }
    }

    .reschedule-slot-container {
      display: flex;
      flex-wrap: wrap;
      margin: 20px 0;

      > div {
        &.past-slot {
          cursor: default;
        }

        cursor: pointer;
        width: 224px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #dfdfdf;
        display: flex;
        margin: 8px;
        margin-left: 0;

        &.selected-slot {
          border: 1px solid #d1b989;
          color: #d1b989;
        }

        > p {
          margin: auto;
          font-family: Avenir;
          font-size: 16px;
        }
      }
    }

    .update-timezone {
      font-family: Avenir;
      font-size: 18px;
      font-weight: 350;
    }

    .availibility-button {
      background: white;
      color: black;
      border: 1px solid black;
      border-radius: 4px;
      padding: 12px 32px;
      height: 40px;
    }

    @media screen and (max-width: $tablet) {
      .reschedule-slot-container > div {
        width: 100px;
        height: 35px;
      }
    }
  }
}

.external-calendar-settings-modal {
  &.steps-mode {
    .cohere-modal {
      .header {
        border-bottom: 1px solid #dfe3e4;
      }
      .body {
        .connected-calendar-container {
          padding: 0;
        }
      }
    }
  }
  .cohere-modal {
    width: 775px;
    .body {
      padding: 0.625rem 1.5rem;
      .connected-calendar-container {
        display: flex;
        padding: 0.875rem 0rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.75rem;
        align-self: stretch;
        border-radius: 0.125rem;
        border: 1px solid #dfe3e4;
        .account-summary {
          border-bottom: 1px solid #dfe3e4;
          height: 2.875rem;
          width: 100%;
          padding: 0 1.25rem 0.62rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.62rem;
          .provider-logo {
            width: 1.875rem;
            height: 1.875rem;
          }
          .email-address-title {
            color: black;
            text-align: center;
            font-family: Avenir;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 800;
            line-height: 1rem;
          }
        }
        .account-settings {
          .step-description {
            color: #000;
            font-family: Avenir;
            font-size: 1rem;
            font-style: normal;
            font-weight: 350;
            line-height: 1.5rem; /* 150% */
            // margin-left: 1rem;
            // margin-right: 1rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            .email {
              color: #000;
              font-family: Avenir;
              font-size: 1rem;
              font-style: normal;
              font-weight: 800;
              line-height: 1.5rem;
            }
          }
          .sub-group {
            gap: 1.25rem;
            .sub-group-title {
              color: rgba(0, 0, 0, 0.87);
              font-family: Avenir;
              font-size: 1rem;
              font-style: normal;
              font-weight: 800;
              line-height: 1.5rem; /* 150% */
            }
            .list-item {
              padding-top: 0;
              padding-bottom: 0;
              &.steps-mode {
                padding: 0;
              }
            }
            .setting-item {
              color: rgba(0, 0, 0, 0.87);
              font-family: Avenir;
              font-size: 1rem;
              font-style: normal;
              font-weight: 350;
              line-height: 1.5rem; /* 150% */
              label {
                margin-bottom: 0;
                align-items: flex-start;
                .MuiFormControlLabel-label {
                  color: rgba(0, 0, 0, 0.87);
                  font-family: Avenir;
                  font-size: 1rem;
                  font-style: normal;
                  font-weight: 350;
                  line-height: 1.5rem; /* 150% */

                  display: inline-block;
                  max-width: 635px;
                  width: 100%;
                  word-wrap: break-word;
                }
                @media screen and (max-width: $mobile-size) {
                  display: flex;
                  align-items: flex-start;
                  .MuiCheckbox-root {
                    padding: 5px 9px;
                  }
                }
                .not-recommended {
                  font-style: italic;
                }
                .primary-calendar-label {
                  color: var(--Cohere-Secondary-Brown, #c9b382);
                  font-family: Avenir;
                  font-size: 1rem;
                  font-style: normal;
                  font-weight: 800;
                  line-height: 1.5rem;
                  margin-left: 5px;
                }
              }
            }
          }
          .sub-group--sub-calendars {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0.62rem;
          }
          .sub-group--1-1-services,
          .sub-group--group-services,
          .sub-group--general-calendar-settings {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1.25rem;
            .setting-item {
              color: rgba(0, 0, 0, 0.87);
              font-family: Avenir;
              font-size: 1rem;
              font-style: normal;
              font-weight: 350;
              line-height: 1.5rem; /* 150% */
              label {
                margin-bottom: 0;
                .not-recommended {
                  font-style: italic;
                }
              }
            }
          }
        }
      }
    }
  }
}

.master-calendar-refuse-model {
  .cohere-modal {
    max-width: 520px;
    width: 100%;
    min-height: auto;

    p {
      margin-bottom: 0;
    }
  }
}

.rescheduleResonInput > div > textarea {
  min-height: 25px;
  font-size: 1rem !important;
}

.day-events-popup {
  .cohere-modal {
    min-width: 340px;

    .day-events-day {
      margin-bottom: 0;
      font-family: Avenir;
      font-size: 12px;
      font-weight: 800;
      color: #71717a;
      margin-top: 10px;
    }

    .day-events-date {
      margin-bottom: 0;
      font-family: Avenir;
      font-size: 22px;
      font-weight: 500;
      color: #000000;
    }

    .day-events-event {
      cursor: pointer;
      display: flex;
      font-family: Avenir;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      color: #a58b62;
      margin-bottom: 10px;

      &.is-past-event {
        cursor: default;
      }

      p {
        margin: auto 0 auto 10px;
      }

      svg {
        margin: auto 0;
      }
    }
  }
}
