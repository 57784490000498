@import "~utils/styles/styles.scss";
.add-my-calendar-modal {
  .cohere-modal {
    width: 775px;
    .body {
      padding: 0;
      .btn-link {
        cursor: pointer;
        border: none;
        background: none;
      }
      .main-container {
        display: flex;
        min-height: 22.1875rem;
        padding: 0.625rem 1.5rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.9375rem;
        align-self: stretch;
        .connected-calendars-list-container {
          width: 100%;
          display: flex;
          padding: 0;
          flex-direction: column;
          align-items: flex-start;
          gap: 1.25rem;
          align-self: stretch;
          .connected-calendar-container {
            display: flex;
            padding: 0.875rem 1.25rem;
            flex-direction: row;
            align-items: center;
            gap: 0.75rem;
            align-self: stretch;
            border-radius: 0.125rem;
            border: 1px solid #dfe3e4;
            height: 3.625rem;
            .account-summary {
              height: 2.875rem;
              width: 100%;
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 0.62rem;
              .provider-logo {
                width: 1.875rem;
                height: 1.875rem;
              }
              .email-address-title {
                > span {
                  color: var(--cohere-greys-000000, #000);
                  text-align: center;
                  font-family: Avenir;
                  font-size: 1.125rem;
                  font-style: normal;
                  font-weight: 800;
                  line-height: 1rem; /* 88.889% */
                }
              }
              .default-label-badge {
                color: var(--Cohere-Primary-White, #fff);
                text-align: center;
                font-family: Avenir;
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1rem; /* 133.333% */
              }
            }
            .btn-settings {
              color: var(--Cohere-Primary-Blue, #215c73);
              text-align: center;
              font-family: Avenir;
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 400;
              line-height: 1rem; /* 114.286% */
            }
          }
        }
        .btn-add-another {
          color: var(--Cohere-Primary-Blue, #215c73);
          text-align: center;
          font-family: Avenir;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 900;
          line-height: 1rem; /* 114.286% */
        }

        .send-one-one-invites-from-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          .invite-header-title {
            color: rgba(0, 0, 0, 0.87);
            font-family: Avenir;
            font-size: 1rem;
            font-style: normal;
            font-weight: 900;
            line-height: 1.5rem; /* 150% */
          }
          .select {
            .MuiSelect-select {
              &:focus {
                background-color: white;
              }
            }
            .MuiInputBase-root {
              // color: #000;
              border: 1px solid #0000003b;
              border-radius: 4px;
              height: 58px;
              min-height: 58px;
              max-height: 58px;
              padding: 5px;
              padding-left: 0.87rem;
              color: var(--cohere-greys-000000, #000);
              // text-align: center;
              font-family: Avenir;
              font-size: 1.125rem;
              font-style: normal;
              font-weight: 800;
              line-height: 1rem; /* 88.889% */
              padding-top: 13px;
            }
            .MuiSelect-icon {
              margin-right: 0.87rem;
            }
            // &.default {
            //   .MuiInputBase-root {
            //     color: #d3d3d3;
            //     border: 1px solid #0000003b;
            //     border-radius: 4px;
            //     height: 60px;
            //     padding: 5px;
            //     padding-left: 0.87rem;
            //   }
            // }
            .MuiInput-underline:hover:not(.Mui-disabled):before {
              border-bottom: unset;
            }
          }
        }
      }
    }
  }
}

.confirmation-modal {
  .cohere-modal {
    width: 775px;
    .header {
      border-bottom: 1px solid #e7e7e7;
      padding: 1.5rem;
      align-items: flex-start;
      @media screen and (max-width: $mobile-size) {
        padding: 1rem;
      }
    }
    .body {
      margin: 1.5rem;
      padding: 0;
    }
  }
}
