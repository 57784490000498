.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  .topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .export_btn_disabled {
      background-color: #ededed;
      color: #9b9b9b;
      fill: #9b9b9b;
      /* for svg */
      cursor: not-allowed;
      border: none;

      svg {
        path {
          fill: #9b9b9b;
        }
      }
    }

    .import_btn_disabled {
      background-color: #ededed;
      color: #9b9b9b;
      fill: #9b9b9b;
      /* for svg */
      cursor: not-allowed;
      border: none;

      svg {
        path {
          fill: #9b9b9b;
        }
      }
    }
  }

  .searchBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    .searchBox {
      background-color: white !important;
    }

    .deleteBtn {
      height: 40px;
      min-width: 75px;
      padding: 7px 14px;
      border: 1px solid #dbe7f2;
      border-radius: 4px;
      background-color: #ffffff;
      font-family: Avenir;
      font-size: 14px;
      font-weight: 800;
      line-height: 20px;
      letter-spacing: -0.24px;
      color: #215c73;
    }

    .addTagBtn {
      height: 40px;
      min-width: 75px;
      padding: 7px 14px;
      border: 1px solid #dbe7f2;
      border-radius: 4px;
      background-color: #ffffff;
      font-family: Avenir;
      font-size: 14px;
      font-weight: 800;
      line-height: 20px;
      letter-spacing: -0.24px;
      color: #215c73;
    }

    .sendEmailBtn {
      height: 40px;
      min-width: 75px;
      padding: 7px 14px;
      border: 1px solid #dbe7f2;
      border-radius: 4px;
      background-color: #ffffff;
      font-family: Avenir;
      font-size: 14px;
      font-weight: 800;
      line-height: 20px;
      letter-spacing: -0.24px;
      color: white;
      background-color: #215c73;
    }

    .filterBtn {
      height: 40px;
      width: 92px;
      padding: 7px 16px;
      border: 1px solid #dbe7f2;
      border-radius: 4px;
      background-color: #ffffff;
      font-family: Avenir;
      font-size: 14px;
      font-weight: 800;
      line-height: 20px;
      letter-spacing: -0.24px;
      color: #215c73;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .contactsTable {
    margin-top: 10px;
  }
}

.delete_modal_main {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 20px;

  .top_heading {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    color: black;
  }

  .form_control_label_container {
    .checkbox_root {
      padding-top: 0px;

      &:hover {
        background-color: transparent;
      }
    }

    .form_control {
      display: flex;
      align-items: flex-start;
    }

    .form_label {
      font-family: Avenir;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: black;
    }
  }

  .contact_list_heading {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 800;
    line-height: 24.5px;
    color: black;
  }

  .contact_list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
}

.email_modal_main {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 20px;
  padding-top: 20px;

  .label {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: #213649;
  }

  .select_container {
    width: 100%;

    .select {
      width: 100%;
    }
  }
}