.Academy-calender-container {
    padding: 0px 10px;

    .academy-calendar {
        /*  &.custom-day-calendar-view {
            .rbc-time-view {
                .rbc-time-content {
                    .rbc-today {
                        background-color: white;
                    }
                }
            }
        } */

        .rbc-month-view,
        .rbc-time-view {
            margin-top: 15px;
        }

        .rbc-month-view {
            border: none;

            .rbc-month-header {
                padding-bottom: 20px;
                padding-top: 8px;

                .rbc-header {
                    border-bottom: none;
                    border-left: none;

                    span {
                        font-family: 'Poppins';
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 20px;
                        text-align: center;
                        color: #9291A5;

                        @media screen and (max-width: 768px) {
                            font-size: 16.2px;
                            line-height: 18px;
                        }
                    }
                }
            }

            .rbc-month-row {
                border-top: none;

                .rbc-row-bg {
                    .rbc-day-bg {
                        border-left: none;
                    }

                    .rbc-today {
                        background-color: transparent;
                    }

                    .rbc-off-range-bg {
                        background-color: transparent;
                    }
                }


            }
        }

        .rbc-time-view {
            max-height: 610px;
            border: none;
            overflow-x: auto;

            /* Width of the scrollbar */
            &::-webkit-scrollbar {
                width: 8px;
            }

            /* Track (the area where the scrollbar is located) */
            &::-webkit-scrollbar-track {
                background: #dfdfdf;
            }

            /* Handle (the draggable part of the scrollbar) */
            &::-webkit-scrollbar-thumb {
                background: #969696;
                border-radius: 10px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #235b72;
            }

            .rbc-time-header {
                position: 'sticky';
                top: 0;
                background-color: white;
                z-index: 99;

                .rbc-time-header-gutter {}

                .rbc-time-header-content {

                    // border: none;
                    .rbc-time-header-cell {
                        .rbc-header {
                            padding-top: 8px;
                            padding-bottom: 8px;

                            a {
                                color: #71717a;
                                font-family: Avenir;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 800;
                                line-height: 12px;
                                float: left;
                            }
                        }
                    }
                }

                .rbc-allday-cell {
                    display: none;
                }
            }

            .rbc-time-content {
                border: none;

                .rbc-day-slot {
                    .rbc-events-container {
                        margin-right: 0;

                        .rbc-event-content {
                            span {
                                margin: auto 0;
                                background-color: #235b72;
                            }

                            svg {
                                min-width: 14px;
                            }
                        }
                    }
                }

                .rbc-timeslot-group {
                    min-height: 72px;
                }

                .rbc-time-gutter {
                    color: #71717a;
                    font-family: Avenir;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                    min-width: 80px;

                    /* 133.333% */
                    .rbc-timeslot-group {
                        border-bottom: none;
                    }
                }
            }
        }

        .rbc-row-content {
            .rbc-row {
                .rbc-date-cell {
                    text-align: center;
                    padding: 5px 0px;

                    a {
                        font-family: 'Poppins';
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 20px;
                        text-align: center;
                        color: #4A4A4A;

                        @media screen and (max-width: 768px) {
                            font-size: 16.2px;
                            line-height: 18px;
                        }
                    }
                }

                .rbc-current {
                    a {
                        font-family: 'Poppins';
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 20px;
                        text-align: center;
                        color: #FFFFFF;
                        background-color: #E1BB62;
                        display: flex;
                        width: 30px;
                        height: 30px;
                        border-radius: 15px;
                        justify-content: center;
                        align-items: center;
                        margin: -3px auto;

                        @media screen and (max-width: 768px) {
                            font-size: 16.2px;
                            line-height: 18px;
                        }
                    }
                }

                .rbc-row-segment {
                    .rbc-event {
                        background-color: transparent;
                        padding: 0px 23px;
                        margin-top: -5px;

                        @media screen and (max-width: 768px) {
                            padding: 0px 19px;
                        }
                    }

                    .rbc-show-more {
                        background: unset;
                    }
                }

                .rbc-off-range {
                    a {
                        font-family: 'Poppins';
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 20px;
                        text-align: center;
                        color: #9291A5;

                        @media screen and (max-width: 768px) {
                            font-size: 16.2px;
                            line-height: 18px;
                        }
                    }
                }
            }
        }
    }
}


.calender-custom-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(223, 227, 228, 1);
    padding-bottom: 15px;

    .back-Button {
        &:hover {
            cursor: pointer;
        }
    }

    .current-Button {
        font-family: 'Poppins';
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        color: rgba(74, 74, 74, 1);

        @media screen and (max-width: 768px) {
            font-size: 16px;
            font-weight: 600;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .next-Button {
        &:hover {
            cursor: pointer;
        }
    }
}