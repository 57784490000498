.allServices {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    .allServicesHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        @media screen and (max-width: 991px) {
            justify-content: center;
        }

        .heading {
            font-family: 'Poppins';
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
        }

        .seeAllLink {
            font-family: 'Poppins';
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            color: #2b2b2b;
            text-decoration: underline;
        }
    }

    .allServicesCards {
        position: relative;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 16px;

        @media screen and (max-width: 768px) {
            grid-template-columns: 1fr;
        }

        .seeAllButton {
            background-color: transparent;
            color: #E1BB62;
            width: 100%;
            min-height: 40px;
            font-size: 16px;
            font-weight: 600;
            font-family: 'Poppins';
            align-items: 'center';
            border: 1px solid #E1BB62;
            border-radius: 8px;
            padding: 0px 16px;
        }

        .scrollIcon {
            position: absolute;
            right: -26px;
            top: 99px;
            cursor: pointer;
        }

        .coachCardBoxShadow {
            position: absolute;
            right: -26px;
            height: 180px;
            top: 0px;
            box-shadow: 0px 0px 15.75px 33px white;
            background-color: white;
        }

        .allCoachCards {
            width: 100%;
            display: flex;
            gap: 16px;
            flex-wrap: wrap;

            @media screen and (max-width: 991px) {
                display: flex;
                gap: 10px;
                width: 100%;
                overflow-x: scroll;
                flex-wrap: nowrap;

            }
        }
    }
}