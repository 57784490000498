.calendar-container {
  margin-top: 24px;

  .custom-calendar {
    width: 100%;
    overflow-x: auto;

    .rbc-toolbar {
      position: relative;
      display: flex;
      justify-content: space-between;

      &-label {
        position: absolute;
        left: 220px;
      }
    }

    .rbc-month-view {
      height: 500px;
    }

    .rbc-time-view {
      overflow-x: scroll;
    }

    @media screen and (max-width: 1200px) {
      .rbc-time-view {
        width: 90vw;
      }

      .rbc-toolbar {
        flex-direction: column;

        &-label {
          position: static;
          margin: 5px 0;
        }
      }
    }

    &.dark-theme {
      .rbc-month-view {
        border-color: #696969;

        .rbc-header,
        .rbc-day-bg,
        .rbc-month-row {
          border-color: #696969;
        }
      }

      .rbc-row-content {
        .rbc-off-range {
          color: white;
        }
      }

      .rbc-off-range-bg {
        background: rgba(221, 221, 221, 0.1);
      }

      .rbc-today {
        background: rgba(221, 221, 221, 0.2);
      }

      .rbc-btn-group {
        button {
          color: white;

          &:hover {
            color: black;
          }
        }
      }
    }
  }
}
.schedule-meeting-form-modal {
  .MuiAutocomplete-popupIndicator {
    height: 20px;
    width: 20px;
  }
  .MuiAutocomplete-clearIndicator {
    display: none;
  }
  .css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator:hover {
    background-color: white;
  }
  .css-113ntv0-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator:hover {
    background-color: white;
  }

  .MuiInput-underline.Mui-disabled:before {
    border-bottom-style: solid !important;
    border-color: #979797 !important;
  }

  .WithStyles\(ForwardRef\(Input\)\)-underline-26:before {
    border-color: #979797 !important;
  }

  .WAMuiChipInput-underline-42.WAMuiChipInput-disabled-41:before {
    border-bottom-style: solid !important;
    border-color: #979797 !important;
  }

  width: 750px !important;
  max-width: 700% !important;
  min-width: 80% !important;

  .MuiGrid-grid-md-6 {
    padding: 0 6px !important;
  }

  .radio-icon-style {
    .MuiButtonBase-root {
      height: 30px !important;
      width: 30px !important;
    }
  }

  .MuiSvgIcon-root {
    height: 1rem;
  }

  .modal-radio-styling {
    .MuiTypography-body1 {
      font-size: 12px;
    }

    margin-bottom: 0 !important;
  }

  width: 600px;

  .select-input-styling {
    // margin-top: -30px !important;
    margin-left: 20px !important;
  }

  .WAMuiChipInput-chipContainer-32 {
    .MuiButtonBase-root {
      height: auto !important;
      width: auto !important;
    }
  }
}

@media (max-width: 750px) {
  .schedule-meeting-form-modal {
    width: 100% !important;
  }
}

.coach-empty-slots-button {
  cursor: pointer !important;
  background: white;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  height: 40px !important;
  border-radius: 4px !important;
  margin: 5px !important;
}

.active-time-slot {
  border-color: #cebb90 !important;
  color: #cebb90 !important;
}

.regular-time-slot {
  border-color: rgb(223, 223, 223) !important;
  color: black;
}

@media (min-width: 576px) {
  .coach-client-name-margins {
    margin: -10px 8px 4px !important;

    .MuiFormLabel-root.Mui-focused {
      color: black !important;
    }

    .MuiInputLabel-formControl {
      color: black !important;
      margin-top: -13px !important;
    }
  }
}

.email-padding {
  .WAMuiChipInput-underline-43.WAMuiChipInput-disabled-42:before {
    border-bottom-style: solid;
  }

  .fxcGeq {
    margin-left: 0px !important;
    max-width: 100% !important;
  }

  .MuiFormLabel-root.Mui-focused {
    color: black !important;

    .MuiInputLabel-formControl {
      color: black !important;
    }
  }
}

.select-input-styling {
  .MuiSelect-select.MuiSelect-select {
    white-space: break-spaces;
  }
}

.AutoSelectField {
  svg {
    height: 30px;
  }
}

// .MuiPopover-paper {
//   margin-top: 5px !important;
// }
.dark-theme-enabled {
  .MuiPaper-root {
    background-color: #696969 !important;
  }
}

.contributionLabelText>div {
  font-family: Avenir;
}

.contributionLabelText {
  ::before{
    content: none !important;
  }
  ::after{
    content: none !important;
  }
  

}

.price-select-popup-dark>.MuiInput-formControl>svg {
  padding-left: 8px;
  color:'white'
}
.price-select-popup>.MuiInput-formControl>svg {
  padding-left: 8px;
}