.servicesContainer {
    min-height: calc(100vh - 271px);
    margin-top: 90px;


    @media screen and (max-width: 768px) {
        min-height: calc(100vh - 152px);
        margin-top: 80px;
        padding: 0px 16px 50px 16px;
        background: linear-gradient(2.64deg, rgba(255, 253, 247, 0) 2.47%, #FFFDF7 42.58%);
    }
}