.loggedOutActions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .loginButton {
        background-color: transparent;
        color: #E1BB62;
        min-width: 80px;
        min-height: 40px;
        font-size: 16px;
        font-weight: 600;
        font-family: 'Poppins';
        align-items: 'center';
        border: 1px solid #E1BB62;
        border-radius: 8px;
        padding: 0px 16px;

        &:hover {
            background-color: transparent;
        }
    }

    .loginResponsiveButton {
        background-color: transparent;
        color: #E1BB62;
        min-width: 66px;
        min-height: 32px;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Poppins';
        align-items: 'center';
        border: 1px solid #E1BB62;
        border-radius: 8px;
        padding: 0px 12px;

        &:hover {
            background-color: transparent;
        }
    }

    .signupButton {
        background-color: #E1BB62;
        min-width: 116px;
        max-width: 116px;
        min-height: 40px;
        font-size: 16px;
        font-weight: 600;
        font-family: 'Poppins';
        border-radius: 8px;
        padding: 0px 16px;
    }

    .signupResponsiveButton {
        background-color: #E1BB62;
        min-width: 89px;
        min-height: 32px;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Poppins';
        border-radius: 8px;
        padding: 0px 12px;
    }
}