.container {
    position: absolute;
    width: 300px;
    background-color: white;
    top: 40px;
    right: -36px;
    z-index: 1;
    border-radius: 5px;
    box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 991px) {
        right: -7px;
    }

    .top_container {
        padding: 15px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #E7E7E7;

        .heading {
            font-family: 'Poppins';
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
            color: #2B2B2B;
        }

        .cross_btn {
            cursor: pointer;
        }
    }

    .body_container {
        padding: 15px 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .main_container {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .heading {

                font-family: 'Poppins';
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
                color: #4A4A4A;
            }

            .filter_container {
                width: 100%;

                .filter {
                    border: 1px solid #E7E7E7;
                    border-radius: 5px;
                    padding: 5px 10px;
                    height: 48px !important;
                }

                .filter_select {
                    &:focus {
                        background-color: white !important;
                    }
                }
            }
        }

        .footerBtn_container {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;

            .cancel_btn {
                width: 123px;
                height: 32px;
                font-family: 'Poppins';
                color: #4A4A4A;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                padding: 0px 12px 0px 12px;
                border-radius: 8px;
                border: 1px solid #4A4A4A;
            }

            .submit_btn {
                width: 123px;
                height: 32px;
                font-family: 'Poppins';
                background-color: #E1BB62;
                color: #FFFFFF;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                padding: 0px 12px 0px 12px;
                border-radius: 8px;
                border: 1px solid #E1BB62;
            }
        }
    }
}