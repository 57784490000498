.boxContainer {
    display: flex;
    height: 44px;
    gap: 24px;

    .active {
        border-bottom: 2px solid #E1BB62;
        display: flex;
        flex-direction: row;
        align-items: center;

        .activeLink {
            background-color: transparent;
            color: #E1BB62;
            min-width: max-content;
            padding: 12px 0px 12px 0px;
            gap: 8px;
            font-family: 'Poppins';
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            text-align: center;
        }
    }

    .inactive {
        border-bottom: 2px solid transparent;
        display: flex;
        flex-direction: row;
        align-items: center;

        .inactiveLink {
            background-color: transparent;
            color: #2B2B2B;
            min-width: max-content;
            padding: 12px 0px 12px 0px;
            gap: 8px;
            font-family: 'Poppins';
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            text-align: center;
        }
    }
}

.responsiveBoxContainer {
    display: flex;
    width: 100%;
    height: 44px;
    overflow-x: scroll;
    overflow-y: hidden;
    gap: 24px;
    padding-right: 40px;

    .active {
        border-bottom: 2px solid #E1BB62;
        display: flex;
        flex-direction: row;
        align-items: center;
        letter-spacing: 2px;

        .responsiveActiveLink {
            background-color: transparent;
            color: #E1BB62;
            min-width: max-content;
            padding: 12px 0px 12px 0px;
            gap: 8px;
            font-family: 'Poppins';
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            text-align: center;
        }
    }

    .inactive {
        border-bottom: 2px solid transparent;
        display: flex;
        flex-direction: row;
        align-items: center;
        letter-spacing: 2px;

        .responsiveInactiveLink {
            background-color: transparent;
            color: #2B2B2B;
            min-width: max-content;
            padding: 12px 0px 12px 0px;
            gap: 8px;
            font-family: 'Poppins';
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            text-align: center;
        }
    }
}