.container {
    position: relative;
    width: 100%;

    .collapsedCategories {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(9, 1fr);
        gap: 8px;
        position: relative;

        @media screen and (max-width: 768px) {
            overflow-x: scroll;
        }

        .category {
            // width: 142.67px;
            // height: 136px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 12px 8px 12px 8px;
            gap: 8px;

            @media screen and (max-width: 768px) {
                // width: 120px;
                // height: 112px;
                padding: 8px;
            }

            .img {
                width: 72px;
                height: 72px;
                border-radius: 36px;

                @media screen and (max-width: 768px) {
                    width: 52px;
                    height: 52px;
                }
            }

            .title {
                font-family: 'Poppins';
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                text-align: center;
            }
        }

        .seeMore {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 142.66px;
            height: 136px;
            padding: 12px 8px 12px 8px;
            gap: 8px;

            @media screen and (max-width: 768px) {
                width: 120px;
                height: 112px;
                padding: 8px;
            }

            .img {
                cursor: pointer;
                width: 72px;
                height: 72px;
                border-radius: 36px;

                @media screen and (max-width: 768px) {
                    width: 52px;
                    height: 52px;
                }
            }

            .text {
                font-family: 'Poppins';
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                text-align: center;
            }
        }
    }

    .scrollIcon {
        position: absolute;
        cursor: pointer;
        right: 0px;
        top: 46px;
    }
}