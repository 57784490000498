.main {
  width: 630px;
  background-color: white;
  border: 1px solid #dbe7f2;
  padding: 16px 24px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: absolute;
  top: -50px;
  left: 450px;
  z-index: 10;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .heading_container {
      display: flex;
      gap: 15px;

      .check_box {
        width: 23px;
        height: 23px;
        border-radius: 12px;
        background-color: #215c73;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .heading {
        font-family: Avenir;
        font-size: 16px;
        font-weight: 800;
        line-height: 24px;
        color: #1e1e1e;
      }
    }

    .cross_btn {
      cursor: pointer;
    }
  }

  .message {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #1e1e1e;
    margin-left: 40px;
  }
}

.mainSidebar {
  width: 630px;
  background-color: white;
  border: 1px solid #dbe7f2;
  padding: 16px 24px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: absolute;
  top: -50px;
  left: 200px;
  z-index: 10;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .heading_container {
      display: flex;
      gap: 15px;

      .check_box {
        width: 23px;
        height: 23px;
        border-radius: 12px;
        background-color: #215c73;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .heading {
        font-family: Avenir;
        font-size: 16px;
        font-weight: 800;
        line-height: 24px;
        color: #1e1e1e;
      }
    }

    .cross_btn {
      cursor: pointer;
    }
  }

  .message {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #1e1e1e;
    margin-left: 40px;
  }
}