.allServiceCrads {
    padding-top: 20px;

    .heading {
        font-family: 'Poppins';
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        color: #000000;
        margin-bottom: 12px;
    }

    .cardsContainer {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
    }
}