.loggedInActions {
    display: flex;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: 768px) {
        gap: 8px;
        justify-content: end;
        width: 100%;
    }

    .createBtn {
        background-color: #E1BB62;
        min-width: 93px;
        min-height: 40px;
        font-size: 16px;
        font-weight: 600;
        font-family: 'Poppins';
        border-radius: 8px;
        padding: 0px 16px;

        &:hover {
            background-color: #E1BB62;
        }
    }

    .createBtnResponsive {
        background-color: #E1BB62;
        min-width: 64px;
        min-height: 30px;
        font-size: 10px;
        font-weight: 600;
        font-family: 'Poppins';
        border-radius: 8px;
        padding: 0px 12px;
    }

    .searchIcon {
        cursor: pointer;
    }

    .favoriteIcon {
        cursor: pointer;
    }

    .chatIcon {
        cursor: pointer;
        position: relative;

        .redDot {
            position: absolute;
            top: -9px;
            right: -4px;
        }
    }

    .notificationIcon {
        cursor: pointer;
        position: relative;

        .redDot {
            position: absolute;
            top: -6px;
            right: -2px;
        }
    }

    .profileImage {
        width: 40px;
        height: 40px;
        border-radius: 22px;
        cursor: pointer;

        @media screen and (max-width : 768px) {
            width: 36px;
            height: 36px;
            border-radius: 18px;

        }
    }

}