@import '~utils/styles/styles.scss';

.community-section-drawer {
  padding: 40px 24px;

  .close-icon {
    display: flex;
    width: 100%;
    justify-content: end;
    margin-bottom: 16px;
  }
}

.MuiDrawer-paperAnchorLeft,
.MuiDrawer-paperAnchorRight {
  width: 80%;
}

.nav-menu {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;

  .menu-item {
    display: flex;
    width: 100%;
    padding: 8px;
    border: 1px solid #dbe7f2;
    overflow: hidden;

    .unread-count {
      border-radius: 50%;
      background-color: #215c73;
      position: absolute;
      top: 0;
      right: 4px;
      padding: 4px 4px;
      font-size: 14px;
    }

    .menu-content {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: flex-start;
      width: 100%;

      .group-image {
        width: 24px;
        height: 24px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .title {
        cursor: default;
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media screen and (max-width: 450px) {
          width: 65%;
        }
      }
    }
  }

  .resources {
    font-size: 16px;
    padding: 8px;
    border: 1px solid #dbe7f2;
    cursor: pointer;
  }
}

.community-right-section {
  display: flex;
  flex-direction: column;

  .right-section-links {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .drag-icon {
    color: #b2cae0;
  }

  .placeholder {
    width: 34px;
  }

  .add-item {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 20px;
    cursor: pointer;
    width: 100%;
    margin-top: 32px;
    margin-bottom: 32px;
    padding: 12px;
    font-weight: 500;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  .right-section-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    padding: 12px;
    cursor: pointer;

    &.active {
      background-color: #eeeeee;
      border-radius: 8px;
    }

    .right-section-item {
      display: flex;
      gap: 16px;
      align-items: center;
      font-weight: 400;
      font-size: 16px;
    }
  }

  .unread-count {
    border-radius: 50%;
    background-color: #215c73;
    color: white;
    font-weight: 400;
    padding: 4px 6px;
    font-size: 14px;
  }
}

.community-left-section {
  display: flex;
  flex-direction: column;
  gap: 32px;

  .heading {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .data-list {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  .add-item {
    display: flex;
    gap: 16px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    margin-top: 20px;
  }

  .community-group-link {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .community-data-title {
      display: flex;
      gap: 16px;
      align-items: center;
      width: 70%;
      padding: 8px;

      svg {
        width: 40px;
        height: 40px;
      }

      .name {
        color: #5f5f5f;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      @media screen and (max-width: 992px) {
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    .community-group-link-actions {
      display: flex;
      align-items: center;
      justify-content: end;
      width: 20%;
      // margin-bottom: 10px;

      .group-link-delete-icon {
        margin-right: 15px;
        cursor: pointer;
      }

      .link-icon {
        cursor: pointer;
      }
    }
  }

  &.dark-mode {
    .community-group-link {
      .community-data-title {
        color: white;

        .name {
          color: white;
        }
      }
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    font-size: 20px;
    font-weight: 500;
    cursor: unset;

    div {
      &:first-child {
        display: flex;
        gap: 10px;
        align-items: center;
        cursor: pointer;
      }
    }

    .MuiButtonBase-root {
      padding: 0px;
    }

    .MuiSvgIcon-root {
      cursor: pointer;
    }

    &.open {
      .MuiSvgIcon-root {
        transform: rotate(90deg);
      }
    }
  }
}

.mobile-content {
  .MuiGrid-container {
    max-width: 100%;
  }
}

.sections-list {
  display: flex;
  flex-direction: column;
  gap: 32px;

  &.margin-top {
    margin-top: 32px;
  }

  &.archived {
    margin-top: 32px;
  }

  .section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    cursor: pointer;
    user-select: none;

    .section-item {
      display: flex;
      gap: 16px;
      width: 85%;
      align-items: center;

      .title {
        width: 100%;
        font-size: 20px;
        font-weight: 500;
        overflow: hidden;
        font-style: normal;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &.open {
        .accordian-arrow {
          transform: rotate(90deg);
        }
      }
    }

    .options {
      justify-content: end;
      padding: 0;
      cursor: pointer;
    }
  }

  .section-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    cursor: pointer;
    user-select: none;

    .item {
      width: 80%;
      display: flex;
      gap: 16px;
      align-items: center;

      svg {
        width: 24px;
        height: 24px;
      }

      &.muted-item {
        width: 66%;
      }

      .title {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
        font-weight: 400;
      }

      .drag-icon {
        color: #b2cae0;
      }
    }

    .item-data {
      display: flex;
      width: 100%;
      gap: 16px;
      overflow: auto;
      text-overflow: ellipsis;
      align-items: center;

      &.create-group {
        .title {
          font-size: 18px;
        }
      }
    }

    .group-image {
      min-width: 24px;
      width: 24px;
      height: 24px;
      text-align: center;
    }

    .options {
      display: flex;
      padding: 0;
      align-items: center;
      justify-content: end;

      &.gap {
        gap: 16px;
      }

      .unread-count {
        border-radius: 50%;
        background-color: #215c73;
        color: white;
        font-weight: 400;
        padding: 2px 8px;
        font-size: 14px;
      }

      .MuiSvgIcon-root {
        cursor: pointer;
      }
    }
  }
}

.load-more {
  text-align: center;
  cursor: pointer;
  margin-top: 16px;
  margin-bottom: 16px;
}

.create-section-popup,
.add-community-links-popup,
.add-community-resources-popup,
.create-group-popup,
.confirmation-popup {
  .cohere-modal {
    max-width: 720px !important;
    width: 100%;
  }

  .modal-error-message {
    color: red;
    font-size: 14px;
    float: right;
  }

  input {
    background-color: #fafafa;
  }
}

.create-group-popup {
  .cohere-modal {
    .header {
      border-bottom: 1px solid #e7e7e7;
    }

    .body {
      padding: 16px 20px;
    }
  }

  .modal-error-message {
    position: absolute;
    top: 60px;
    right: 42px;
  }
}

.create-group-popup {
  .drag-icon {
    color: #b2cae0;
  }

  .modal-error-message {
    color: red;
    font-size: 14px;
    float: right;
  }

  .add-item {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 20px;
    cursor: pointer;
    width: 100%;
    padding: 12px;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  .create-groups {
    overflow: auto;
    max-height: 40vh;

    @media screen and (max-width: $laptop) {
      max-height: 34vh;
    }
  }

  .create-groups,
  .edit-group {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .cohere-modal {
    >div:nth-child(2) {
      position: relative;
    }
  }

  &.edit-group {
    .cohere-modal {
      >div:nth-child(2) {
        overflow: inherit;
      }
    }
  }

  .group-image {
    max-width: 32px;
    width: 32px;
    height: 32px;
    object-fit: contain;
  }

  .group-item {
    padding: 8px;
    border-radius: 8px;
    background-color: #f1f8ff;
  }

  .suggested-title {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 16px;
  }

  .suggested-tile {
    display: flex;
    width: 100%;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #dbe7f2;
    padding: 5px 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    line-height: 16px;
    gap: 16px;

    .MuiIconButton-root {
      padding: 0;
    }

    .MuiFormControlLabel-root {
      width: 100%;
      margin-left: 0;
      gap: 20px;
    }

    .suggested-body {
      display: flex;
      width: 100%;
      gap: 20px;
      align-items: center;
    }

    .MuiFormControlLabel-root {
      margin-right: 0px;
      margin-bottom: 0px;
    }

    img {
      width: 32px;
      height: 32px;
    }
  }

  .emoji-picker-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 50;
  }

  .group-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .client-posting {
      display: flex;
      padding: 0 44px;

      .MuiFormControlLabel-root {
        margin-bottom: 0px;

        .MuiButtonBase-root {
          padding: 0 9px;
        }
      }

      @media screen and (max-width: $mobile-size) {
        padding: 0 40px;
      }

      @media screen and (max-width: 1320px) {
        padding: 0 14px;
      }

      @media screen and (max-width: $mobile-size-sm) {
        padding: 0 10px;
      }
    }

    .group {
      display: flex;
      gap: 16px;
      align-items: center;
      position: relative;
    }

    .input-container {
      display: flex;
      flex: 1;
      gap: 16px;
      align-items: center;

      .MuiSvgIcon-root {
        width: 32px;
        height: 32px;
      }

      .MuiIconButton-root {
        color: inherit;
      }

      .input-field {
        input {
          background-color: #fafafa;
        }

        .MuiOutlinedInput-root {
          border-radius: 5px;
          overflow: hidden;
        }

        font-size: 16px;
        border: 1px solid #dbe7f2;
        border-radius: 5px;
        overflow: hidden;

        .MuiOutlinedInput-notchedOutline {
          border-width: 0;
        }
      }
    }
  }
}

.active-group {
  background-color: #eeeeee;
  border-radius: 8px;
}

.cohere-modal-dark {
  .MuiInputBase-root {
    input {
      background-color: #252728 !important;
      padding-left: 10px;
    }
  }

  .group-item {
    background-color: #121314;
  }

  .client-posting {
    .MuiSvgIcon-root {
      color: white;
    }
  }
}

.add-community-resources-popup {
  .cohere-modal {
    .upload-resource-container {
      height: fit-content;
      border-color: #215c73;
      border-width: 2px;
      border-radius: 5px;
      border-style: solid;

      >p {
        margin: 0;
      }

      &.dark-mode {
        color: white;

        >p {
          color: white !important;
        }
      }
    }

    .upload-group-resource-button {
      display: flex;

      p {
        margin: auto auto auto 10px;
      }

      svg {
        margin: auto;
      }
    }

    .community-group-files-container {
      display: flex;
      border-color: #215c73;
      border-width: 2px;
      border-radius: 5px;
      border-style: solid;
      margin-bottom: 10px;
      width: 100%;
      padding: 5px 2px;

      .upload-progress {
        position: relative;
        width: fit-content;
        margin: auto 0 auto auto;

        .upload-progress {
          width: 32px !important;
          height: 32px !important;
        }

        .MuiTypography-caption {
          font-size: 9px;
        }
      }

      p {
        margin: auto 0 auto 10px;
      }

      .delete-files-icon {
        cursor: pointer;

        svg {
          width: 24px;
        }

        margin: auto 10px;
      }
    }
  }
}

.add-community-links-popup {
  .cohere-modal-dark {
    .add-link-header {
      .add-link-heading {
        color: white;
        opacity: 80%;
      }
    }
  }

  .modal-add-link-button {
    display: flex;
    margin: 10px 0;
    width: 100%;

    svg {
      margin: auto 0;
    }

    .modal-add-link-button-text {
      margin: auto 0 auto 15px;
      font-size: 20px;
    }
  }

  .add-link-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .delete-link-community-modal {
      cursor: pointer;

      svg {
        width: 24px;
      }
    }

    .add-link-heading {
      margin-top: 10px;
      margin-bottom: 0;
      color: #47627b;
    }
  }

  .community-links-input-field {
    margin-top: 8px;
  }
}

.contribution-community-router {
  .chat-container {
    width: 90%;
    margin: 0 auto;
  }
}

.edit-post-modal {
  .cohere-modal {
    width: 100%;
    // max-width: 620px;
  }
}

.dark-mode-right-section {
  .right-section-link {
    &.active {
      background-color: #121314;
    }
  }

  .active-group {
    background-color: #121314;
  }
}