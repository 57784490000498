.boxContainer {
    width: 100%;
    height: 62px;
    padding: 0px 24px 0px 24px;
    gap: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    background-color: #000000;
    border-top: 1px solid #4A4A4A;
    position: fixed;
    bottom: 0px;
    left: 0px;

    .inactive {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1px;

        .name {
            font-family: 'Poppins';
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            text-align: center;
        }
    }

    .active {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1px;

        .name {
            font-family: 'Poppins';
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            text-align: center;

        }
    }
}