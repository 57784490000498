.container {
    position: relative;

    .servicesDropdown {
        display: flex;
        align-items: center;
        gap: 8px;
        height: 80px;
        border-bottom: 3px solid #000000;
        cursor: pointer;
        background-color: transparent;
        padding: 1rem 0;
        min-width: max-content;
        max-width: max-content;
        min-height: 12px;
        max-height: 12px;

        &:hover {
            background-color: transparent;
        }

        .title {
            font-family: 'Poppins';
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            text-align: left;
            color: #FFFFFF;
            padding: 1rem 0px;
            letter-spacing: 1px;
        }
    }

    .navbarDropdown {
        position: absolute;
        top: 35px;
        left: 0px;
        width: 146px;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
        background-color: rgba(255, 255, 255, 1);

        .dropdownItem {
            font-family: 'Poppins';
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: rgba(43, 43, 43, 1);
            padding: 10px 12px 10px 12px;
            cursor: pointer;
        }
    }
}