.calenderBox {
    display: flex;
    padding: 7px 16px 7px 16px;
    gap: 8px;
    cursor: pointer;

    @media screen and (max-width : 991px) {
        padding: 0px;
    }

    .text {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        color: #2B2B2B;
    }
}