.headerContainer {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000000;
    color: #FFFFFF;
    padding: 0px 80px;
    position: fixed;
    top: 0px;
    z-index: 200;

    @media screen and (max-width: 768px) {
        height: 80px;
        padding: 0px 16px;
        gap: 8px;
    }

    .leftCol {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 24px;

        @media screen and (max-width: 768px) {
            gap: 4px;

            &.headerLogo {
                margin-bottom: 0px;
            }
        }

        .headerLogo {
            margin-bottom: 3px;
        }

        .searchIcon {
            cursor: pointer;
        }
    }

    .rightCol {
        display: flex;
        justify-content: end;
        align-items: center;
        height: 40px;
        flex-basis: 35%;

        @media screen and (max-width: 768px) {
            flex-basis: 100%;
        }


        .headerText {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            cursor: pointer;
            width: 100%;

            p:first-child {
                font-family: 'Poppins';
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                margin: 0px;
            }

            p:last-child {
                font-family: 'Poppins';
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                margin: 0px;
            }
        }

        .separator {
            width: 1px;
            align-self: stretch;
            margin: 8px 15px;
            background-color: #4A4A4A;
        }
    }
}