.card {
    // width: 323px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    gap: 8px;
    position: relative;
    // height: max-content;

    @media screen and (max-width: 768px) {
        width: 100%;
        border-bottom: 1px solid #F5F5F5;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    &:hover {
        box-shadow: 0 1px 3px rgba(23, 23, 23, 0.24);
        cursor: pointer;

        .hoverIcons {
            display: flex;
            gap: 4px;
            position: absolute;
            top: 12px;
            right: 12px;
            cursor: pointer;
        }
    }

    .hoverIcons {
        display: none;

        @media screen and (max-width: 991px) {
            display: flex;
            gap: 4px;
            position: absolute;
            top: 12px;
            right: 12px;
            cursor: pointer;

        }
    }

    .image {
        width: 100%;
        // height: 172px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    .description {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 10px;

        .title {
            font-family: 'Poppins';
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
            color: #2B2B2B;
            min-height: 52px;
        }

        .categoryPayment {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .category {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 78px;
                height: 21.34px;
                padding: 2px 8px 2px 8px;
                gap: 4px;
                border-radius: 4px;
                background: #F5F5F5;

                .categoryName {
                    font-family: 'Poppins';
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 18px;
                    color: #4A4A4A;
                }
            }

            .paymentOption {
                flex-basis: 50%;
                font-family: 'Poppins';
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                text-align: left;
                color: #4A4A4A;
            }
        }

        .coachProfile {
            width: 165px;
            height: 32px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;

            .coachImage {
                width: 32px;
                height: 32px;
                border-radius: 16px;
            }

            .coachName {
                font-family: 'Poppins';
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
                color: #4A4A4A;
            }
        }

        .calender {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 4px;

            .date {
                font-family: 'Poppins';
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                color: #4A4A4A;
            }
        }
    }

}