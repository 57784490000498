.AcademyPageContainer {
    min-height: calc(100vh - 271px);
    margin-top: 80px;
    padding: 52px 82px 52px 82px;
    background: linear-gradient(2.64deg, rgba(255, 253, 247, 0) 2.47%, #FFFDF7 42.58%);

    @media screen and (max-width: 768px) {
        padding: 32px 16px 16px 16px;
        min-height: calc(100vh - 152px);
        margin-top: 90px;
    }

    .detailsSection {
        max-width: 1160px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        margin: 0 auto;
        padding-bottom: 90px;

        @media screen and (max-width: 768px) {
            padding-bottom: 40px;
        }

        .title {
            font-family: 'Poppins';
            font-size: 52px;
            font-weight: 700;
            line-height: 62px;
            color: rgba(43, 43, 43, 1);

            @media screen and (max-width : 768px) {
                font-size: 31px;
                line-height: 35px;
                text-align: center;
            }
        }

        .subTitle {
            font-family: 'Poppins';
            font-size: 23px;
            font-weight: 600;
            line-height: 32px;
            text-align: center;
            color: rgba(43, 43, 43, 1);
        }

        .text {
            font-family: 'Poppins';
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            text-align: center;
            color: rgba(43, 43, 43, 1);
        }
    }

    .wrapper {
        display: flex;
        align-items: center;
        gap: 8px;

        .searchComponent {
            flex-basis: 50%;

            @media screen and (max-width :991px) {
                flex-basis: 100%;
            }
        }
    }

    .academyServices {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 40px 0px;

        .heading {
            font-family: 'Poppins';
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            color: rgba(43, 43, 43, 1);
        }

        .academyServicesCards {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 16px;

            @media screen and (max-width : 991px) {
                grid-template-columns: 1fr;
            }
        }
    }

    .upcomingSessions {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding-bottom: 50px;
    }
}


.responsiveContainer {
    min-height: calc(100vh - 420px);
    margin-top: 80px;
}