.footerContainer {
    width: 100%;
    /*  height: 191px; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #000000;
    padding: 30px 50px;
    gap: 41px;

    .footerTop {
        display: flex;
        justify-content: space-between;
        align-items: center;


        .centerCol {
            display: flex;
            color: #ffff;
            gap: 80px;
            margin-right: 30px;
            font-family: 'Poppins';

            .centerColLink {
                cursor: pointer;
            }
        }

        .rightCol {
            display: flex;
            gap: 25px;
            align-items: center;

            .socialIconLink {
                cursor: pointer;
            }
        }
    }

    @media screen and (max-width: 991px) {
        .footerTop {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;

            .centerCol {
                flex-direction: column;
                gap: 20px;
                align-items: center;
                margin-right: 0px;
            }
        }
    }

    .footerBottom {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 14px;
        line-height: 19.6px;
        color: #FFFFFF;
        text-align: center;
    }
}

@media screen and (max-width: 991px) {
    .footerContainer {
        padding: 20px 60px;
        gap: 40px;
    }

}