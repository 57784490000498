@import 'src/utils/styles/styles';

.easy-booking-page {
  &.cohere-dark-mode {
    .easy-booking-header {
      color: black;
      background-color: #3f95b6;
    }

    .easy-booking-container {
      background-color: #696969;

      .user-details-side {
        background-color: #252728;

        .user-details {
          p {
            color: #3f95b6;
          }
        }

        .duration-details {
          color: white;
        }

        .contribution-title {
          color: white;
        }
      }

      .calendar-side {
        background-color: #252728;

        .one-one-session-card {
          background-color: #252728 !important;

          .calendar-container-custom {
            .custom-calendar {
              background-color: #252728 !important;
            }
          }

          .price-container {
            background-color: #252728 !important;
          }
        }

        .one-one-session-slot-card {
          background-color: #252728 !important;

          .easy-booking-slots {
            border: 1px solid #f5f5f550;
          }

          .easy-booking-timezone {
            .easy-booking-timezone-select {
              border: 1px solid #696969;
              background-color: #2d2f31 !important;

              select {
                color: white;
                opacity: 0.8;
              }
            }

            > div {
              p {
                color: #3f95b6;
              }
            }
          }
        }
      }
    }
  }

  .book-your-next-session {
    display: none;
  }

  .easy-booking-header {
    background-color: #215c73;
    color: #fff;
    margin: auto;
    width: 777px;
    font-family: Avenir;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 56px;
  }

  .easy-booking-container {
    display: flex;
    background-color: #f5f5f5;
    width: fit-content;
    padding: 2px;
    border-radius: 2px;
    margin: auto;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);

    .user-details-side {
      background-color: white;
      width: 323px;
      margin-right: 2px;
      padding: 20px;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;

      .easy-booking-purpose {
        margin-top: 15px;
      }

      .user-details {
        display: flex;

        > div {
          margin: auto 0;
          width: 30px;
          height: 30px;
        }

        p {
          margin: auto auto auto 10px;
          color: var(--cohere-primary-blue, #215c73);
          font-family: Avenir;
          font-size: 14px;
          font-style: normal;
          font-weight: 850;
          line-height: normal;
        }
      }

      .duration-details {
        margin-top: 10px;
        color: #000;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Avenir;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .contribution-title {
        margin-top: 10px;
        color: #000;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Avenir;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .selected-date-time {
        display: flex;
        margin-top: 10px;
        font-family: Avenir;
        font-size: 16px;
        font-weight: 850;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;

        p {
          margin: auto 0 auto 10px;

          &.selected-date {
            margin-right: 10px;
          }
        }

        svg {
          margin: auto 0;
        }
      }
    }

    .calendar-side {
      width: 448px;
      background-color: white;
      padding: 20px;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;

      .single-session-back-button {
        position: absolute;
        margin-top: 2px !important;

        &.free-booking {
          display: block !important;
          margin-top: 0 !important;
        }

        > div {
          margin-top: 0 !important;
        }
      }

      .one-one-session-slot-card {
        background-color: white !important;

        .easy-booking-slots {
          border: 1px solid #f5f5f5;
          border-radius: 5px;
        }

        .easy-booking-timezone {
          .easy-booking-timezone-select {
            border-radius: 2px;
            border: 1px solid white;
            box-shadow: 0px 4px 17px 0px #0000000a;

            select {
              color: #696969;
            }
          }

          > div {
            width: 100% !important;

            p {
              color: #215c73;
              margin-bottom: 5px;
            }
          }
        }

        .easy-booking-slot-buttons {
          margin-top: 10px;

          > div {
            width: 100% !important;
            justify-content: right !important;

            &:first-of-type {
              justify-content: left !important;
            }

            button {
              width: 90% !important;
              border-radius: 5px !important;
            }
          }
        }

        .single-session-back-button {
          > div {
            > b {
              display: none;
            }

            .back-for-easy-booking {
              display: block;
            }
          }
        }

        .easy-booking-session-time-title {
          font-family: Avenir;
          font-size: 20px;
          font-weight: 850;
          line-height: 32px;
          letter-spacing: 0em;
          color: #215c73;
        }

        .slot-selection-description {
          margin-top: 20px;

          > p {
            display: none;

            &.no-slot-selected {
              display: block;
            }
          }
        }
      }

      .one-one-session-card {
        background-color: white !important;
        box-shadow: none;

        .session-date-time {
          display: none;
        }

        .price-container {
          background-color: white !important;
        }

        .one-one-session-header {
          display: none !important;
        }
      }

      .session-details {
        margin-bottom: 20px;

        .session-price-title {
          width: fit-content;
          background-color: #c9b382;
          color: white;
          width: 110px;
          height: 26.75px;
          padding: 4px 8px;
          border-radius: 4px;
          margin: auto;
          font-family: Avenir;
          font-size: 16px;
          font-weight: 350;
          line-height: 20px;
          letter-spacing: 0em;
        }

        .total-price {
          font-family: Avenir;
          font-size: 32px;
          font-weight: 900;
          line-height: 54px;
          letter-spacing: 0em;
          text-align: left;
          margin: 5px auto;
          width: fit-content;

          span {
            margin-left: 5px;
            font-weight: 300;
          }
        }

        .processing-fee,
        .due-now {
          width: 288px;
          display: flex;
          margin: auto;

          .payment-title {
            font-family: Avenir;
            font-size: 16px;
            font-weight: 350;
            line-height: 34px;
            letter-spacing: 0em;
            text-align: left;
          }

          .payment-amount {
            font-family: Avenir;
            font-size: 20px;
            font-weight: 800;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            margin-left: auto;
          }

          .payment-currency {
            font-family: Avenir;
            font-size: 20px;
            font-weight: 300;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            margin-left: 5px;
          }
        }
      }

      .booking-details {
        padding: 0 10px 0 28px;

        p {
          margin-bottom: 0;
          color: var(--cohere-primary-blue, #215c73);
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Avenir;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .toolbar-container {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        height: 0px;
        margin-bottom: 67px;
        padding: 0px 10px 0px 5px;
      }

      .btn-back-container {
        margin: auto 0 auto auto;

        .btn-back {
          border: none;
          padding: 10px;
          margin: 3px;
          font-size: 30px;
          color: gray;

          &.enabled-arrow {
            color: black;
          }
        }
      }

      .btn-current {
        border: none;
        padding: 8px 2px;
        color: #282b2b;
        font-family: Avenir;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .btn-next {
        border: none;
        padding: 10px 8px;
        margin: 3px;
        font-size: 30px;
        color: gray;

        &.enabled-arrow {
          color: black;
        }
      }

      .label-date {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .easy-booking-slots-container {
        .slot-header {
          display: flex;
          font-family: Avenir;
          font-size: 20px;
          font-weight: 850;
          line-height: 31px;
          letter-spacing: 0em;
          text-align: left;
          color: #215c73;

          p {
            margin: auto 0 auto 10px;
          }

          span {
            cursor: pointer;

            svg {
              margin-bottom: 5px;
            }
          }
        }

        .time-zone-selection-container {
          p {
            color: var(--cohere-primary-blue, #215c73);
            font-family: Avenir;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          .time-zone-selection-dropdown {
            > div {
              width: 100%;
              border: 1px solid;
              border-radius: 5px;
              border-color: rgba(0, 0, 0, 0.04);
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: $mobile-size) {
    padding: 10px;

    .easy-booking-header {
      width: 100%;
      max-width: 365px;
      margin-top: 16px;
    }

    .easy-booking-container {
      display: block;

      .user-details-side {
        .easy-booking-purpose {
          display: none;
        }

        width: unset;
        margin-right: 0;
        text-align: center;
        margin-bottom: 2px;

        .selected-date-time {
          justify-content: center;
        }

        .user-details {
          > div {
            margin: auto 0 auto auto;
          }
        }
      }

      .calendar-side {
        width: unset;
        min-width: 365px;
        text-align: center;
        padding: 0;

        .session-details {
          padding: 20px 0;
          border-bottom: 2px solid #f5f5f5;
        }

        .easy-booking-slots-container {
          max-width: 365px;
          padding: 10px;

          .slot-header {
            justify-content: center;
            margin-bottom: 20px;
          }

          .time-zone-selection-container {
            padding: 20px;

            p {
              text-align: left;
            }
          }
        }

        .booking-details {
        }
      }
    }
  }
}

.calendar-container-custom {
  margin-top: 0px;
  padding: 0px 16px 16px 16px;
  font-family: 'Brandon Text';
  justify-content: center;
  font-style: normal;
  font-size: 17px;
  font-weight: 600;
  max-width: 500px;

  &.cohere-dark-mode {
    .custom-calendar {
      background-color: #252728;

      .rbc-month-view {
        .rbc-header {
          color: white;
        }

        .rbc-month-row {
          .rbc-row-content {
            .rbc-row {
              color: white;

              .rbc-now {
                color: black;
              }
            }
          }
        }
      }
    }
  }

  .custom-calendar {
    width: 100%;
    overflow-x: auto;
    background-color: white;
    border-radius: 25px;
    padding: 0;
    padding-top: 0px;
    box-shadow: none;

    .rbc-toolbar {
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;

      &-label {
        position: absolute;
        left: -9px;
        font-size: 50px;
        font-family: 'Brandon Text';
        font-style: normal;
        font-weight: 700;
      }
    }

    .rbc-month-view {
      height: unset;
      border: none;
      font-family: Avenir;

      .rbc-header {
        border-bottom: none;
        color: black;
        text-align: center;
        font-family: Avenir;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 28.333px;
        /* 141.667% */
      }

      .rbc-header + .rbc-header {
        border-left: none;
      }

      .rbc-month-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-basis: unset;
        height: 44px;

        .rbc-row-content {
          .rbc-row {
            .rbc-now {
              color: white;
            }

            .selected-date-container {
              cursor: pointer;
              position: relative;

              .selected-date {
                position: absolute;
                bottom: 0px;
                left: 17px;
                font-weight: 400;
                font-size: 17px;
                color: white;

                @media screen and (max-width: $mobile-size) {
                  left: 13px;
                }
              }
            }
          }
        }

        .rbc-row-bg {
          justify-content: space-around;
          margin-left: 2px;

          .rbc-day-bg {
            margin-top: 4px;
          }
        }
      }

      .rbc-month-row + .rbc-month-row {
        border-top: none;
      }

      .rbc-date-cell {
        text-align: center;
        padding-right: 0px;
        font-weight: 400;
      }
    }

    .rbc-time-view {
      overflow-x: scroll;
    }

    @media screen and (max-width: 1200px) {
      .rbc-time-view {
        width: 90vw;
      }

      .rbc-toolbar {
        flex-direction: column;

        &-label {
          position: static;
          margin: 5px 0;
        }
      }
    }
  }
}

.single-session-back-button {
  &.free-booking {
    display: none !important;
  }
}

.back-for-easy-booking {
  display: none;
}
