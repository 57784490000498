.card {
    // width: 307px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    gap: 8px;
    position: relative;

    @media screen and (max-width: 768px) {
        width: 100%;
        border-bottom: 1px solid #F5F5F5;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    &:hover {
        box-shadow: 0 1px 3px rgba(23, 23, 23, 0.24);
        cursor: pointer;

        .hoverIcons {
            display: flex;
            gap: 4px;
            position: absolute;
            top: 12px;
            right: 12px;
            cursor: pointer;
        }
    }

    .hoverIcons {
        display: none;

        @media screen and (max-width: 991px) {
            display: flex;
            gap: 4px;
            position: absolute;
            top: 12px;
            right: 12px;
            cursor: pointer;

        }
    }

    .image {
        width: 100%;
        // height: 172px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    .lockedIcon {
        position: absolute;
        top: 65px;
        left: 142px;

        @media screen and (max-width: 768px) {
            top: 68px;
            left: 177px;
        }
    }

    .description {
        padding: 0px 12px 12px 12px;

        .title {
            font-family: 'Poppins';
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
            color: #2B2B2B;
            min-height: 52px;
            margin-bottom: 8px;
        }

        .subTitle {
            font-family: 'Poppins';
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            text-align: left;
            color: rgba(74, 74, 74, 1);
            margin-bottom: 8px;
        }

        .calender {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
            margin-bottom: 8px;

            .date {
                font-family: 'Poppins';
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                color: #4A4A4A;
            }
        }

        .enrollBtn {
            background-color: #E1BB62;
            min-width: 77px !important;
            min-height: 40px !important;
            font-size: 16px !important;
            font-weight: 600 !important;
            font-family: 'Poppins' !important;
            border-radius: 8px !important;
            padding: 0px 16px !important;
            float: right;
        }

        .responsiveBtn {
            background-color: transparent;
            color: #E1BB62;
            width: 100%;
            min-height: 40px;
            font-size: 16px;
            font-weight: 600;
            font-family: 'Poppins';
            align-items: 'center';
            border: 1px solid #E1BB62;
            border-radius: 8px;
            padding: 0px 16px;
        }
    }

}