.categoryWrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;

    @media screen and (max-width: 991px) {
        align-items: center;
    }

    .heading {
        font-family: 'Poppins';
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        text-align: center;
        padding: 0px 8px;

    }

    .content {
        display: grid;
        gap: 24px;
        grid-template-columns: repeat(9, 1fr);

        .category {
            // width: 125px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 12px 5px 12px 5px;
            grid-gap: 8px;
            gap: 8px;
            transition: transform 0.1s ease;

            &:hover {
                transform: scale(1.1);

                .title {
                    font-weight: 500;
                }
            }

            @media screen and (max-width: 991px) {
                width: 120px;
                height: 112px;
                padding: 8px;
            }

            .img {
                cursor: pointer;
                width: 72px;
                height: 72px;
                border-radius: 36px;

                @media screen and (max-width: 991px) {
                    width: 52px;
                    height: 52px;
                }
            }

            .title {
                font-family: 'Poppins';
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                text-align: center;
            }
        }
    }
}

.seeLess {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 142.66px;
    height: 114px;
    padding: 12px 8px 12px 8px;
    gap: 8px;

    @media screen and (max-width: 991px) {
        width: 120px;
        height: 112px;
        padding: 8px;
    }

    .img {
        cursor: pointer;
        width: 72px;
        height: 72px;
        border-radius: 36px;

        @media screen and (max-width: 991px) {
            width: 52px;
            height: 52px;
        }
    }

    .text {
        font-family: 'Poppins';
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: center;

    }
}