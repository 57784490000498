.mobileCard {
    display: flex;
    align-items: center;
    border: 0.8px solid #F5F5F5;
    border-radius: 8px;
    gap: 8px;

    img {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    .cardDetails {
        .title {
            font-family: 'Poppins';
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
            text-align: left;
            color: #2B2B2B;
        }

        .noOfSerives {
            font-family: 'Poppins';
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            color: #4A4A4A;
        }
    }
}